//
// Nav Language CSS
//

.nav-language {
  position: absolute;
  top: 11px;
  right: 18px;

  ul {
    list-style: none;
    margin: 0 auto;
    padding: 0;

    li {
      font-family: Montserrat, sans-serif;
      @include font-size(18);
      font-weight: 400;
      display: inline-block;
      padding-left: 5px;

      a {
        color: $black;
        text-decoration: none;

        &.active {
          //border-bottom: 1px solid $black;
          //font-weight: 700;
        }
      }
    }
  }


  @media screen and (max-width: $size-L) {
    top: 38px;
    right: 22px;

    ul {

      li {
        display: block;
      }
    }

  }

}
