//
// Custom CE Image Grid CSS
//

.ce-image-grid {

  overflow: hidden;
  margin-top: 20px;
  margin-left: 235px;
  padding-left: 20px;

  .ce-image-grid-wrap {
    overflow: hidden;
    margin-left: -19px;

    figure {
      float: left;
      width: 50%;
      margin-bottom: 20px;
      padding-left: 19px;

      img {
        display: block;
      }
    }
  }


  @media screen and (max-width: $size-L) {
    margin-left: 0;
    padding-left: 0;
  }

  @media screen and (max-width: $size-S) {

    .ce-image-grid-wrap {
      margin-left: 0;

      figure {
        float: none;
        width: auto;
        padding-left: 0;
      }
    }

  }

}