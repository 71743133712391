//
// News CSS
//


// list View
.news-list-view {

  article {
  }

  a {
    text-decoration: none;
  }

  .news-list-item-content {
    overflow: hidden;
    margin-bottom: 30px;
    background: $grey;
    min-height: 140px;

    h2 {
      margin: 0;
    }
    h3 {
      margin: 0;
    }
  }

  .news-list-date {
    float: left;
    padding: 20px;
    width: 115px;
    font-family: Montserrat, sans-serif;
  }

  .news-list-date-day {
    @include font-size(24);
  }
  .news-list-date-month {
    @include font-size(24);
    float: left;
  }
  .news-list-date-weekday {
    float: right;
    transform: rotate(270deg);
    //width: 19px;
    width: 28px;
    writing-mode: horizontal-tb;
  }

  .news-list-text {
    float: left;
    padding: 20px;
  }

  .news-list-info {
    font-family: Montserrat, sans-serif;
    @include font-size(15);
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  .article-type-slider {

    .news-list-item-content {
      background: none;
    }

    .news-list-text-wrap {
      float: left;
      width: 385px;
    }

    .news-img-wrap {
      float: left;
      width: 255px;
      padding-right: 20px;
    }

    .news-list-date {
      float: none;
      padding: 0;
      overflow: hidden;
    }

    .news-list-text {
      float: none;
      padding: 10px 0 0 0;
    }

  }

}


// pagination
.f3-widget-paginator {
  @include ul-reset;
  display: flex;
  gap: 8px;
  flex-flow: row wrap;
  margin-bottom: 20px;

  li {

    &.current {
      font-weight: 700;
    }

    &.current,
    a {
      display: block;
      padding: 3px 5px;
    }
  }
}


// detail View
.news-single {


  .footer {
    margin: 20px 0 0 255px;
  }

  .social-media {
    margin-top: 15px;
    div {
      margin-bottom: 10px;
    }
  }

  .news-backlink-wrap {
    display: none;
  }

  @media screen and (max-width: $size-L) {
    .footer {
      margin: 0;
    }
  }

}
