//
// Custom CE Text With Images CSS
//

.ce-text-with-images {

  overflow: hidden;

  .ce-text-with-images-wrap {
    float: left;
    clear: left;
    width: 235px;
  }

  .bodytext {
    float: right;
    padding-left: 20px;
    width: 425px;
  }

  .header-content {
    display: inline-block;
    position: relative;
    background-color: $grey;
    float: right;
    width: 405px;
    margin: 55px 0 20px 20px;
    min-height: 140px;
    overflow: hidden;
    padding: 20px;

    .header-topic {
      font-family: Montserrat, sans-serif;
      @include font-size(14);
      text-transform: uppercase;
    }

    h1 {
      margin: 0;
    }
    h2 {
      margin: 0;
    }
    h3 {
      margin: 0;
    }
  }

  figure {
    img {
      display: block;
    }
  }

  figcaption {
    font-family: Montserrat, sans-serif;
    @include font-size(10);
    line-height: 15px;
    text-align: right;
  }

  @media screen and (max-width: $size-L) {

    .header-content {
      display: block;
      width: auto;
      float: none;
      margin: 0 0 15px 0;
    }

    .ce-text-with-images-wrap {
      display: block;
      width: auto;
      float: none;
      padding: 0;
      margin-bottom: 15px;
    }

    .bodytext {
      display: block;
      width: auto;
      float: none;
      padding: 0;
    }

    figcaption {
      text-align: left;
    }

  }

}

.content-deco {
  float: left;
  margin: 0;
  padding: 0 0 20px 126px;;
  width: 235px;

  .content-deco-box {
    background-color: $grey;
    height: 80px;
    width: 108px;
  }

  @media screen and (max-width: $size-L) {
    display: none;
  }

}

.header-layout-type-2,
.header-layout-type-3 {
  .content-deco-box {
    background-color: transparent;
  }
}