//
// Footer CSS
//

body > footer {

  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 200px;
  width: 100%;
  text-align: center;
  //width: 1020px;

  ul {

    margin-left: 0;
    padding-left: 0;

    li {
      display: inline-block;
      font-family: Montserrat, sans-serif;
      font-weight: 400;
      letter-spacing: 1px;
      padding-left: 15px;

      &:first-child {
        padding-left: 0;
      }

      a {
        color: $black;
        text-decoration: none;
        text-transform: uppercase;
      }
    }

  }


}