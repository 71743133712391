//
// Header CSS
//

.page > header {

  overflow: hidden;
  max-width: 1400px;
  padding: 0 20px;
  margin: auto;

  .header-title {
    position: relative;
    float: left;
    font-family: Montserrat, sans-serif;
    @include font-size(18);
    font-weight: 700;
    text-transform: uppercase;
    //width: 216px;

    a {
      text-decoration: none;
    }

    .header-title-small {
      font-weight: 400;
      @include font-size(16);
      text-transform: none;
    }
  }

  .logo {
    position: relative;
    float: right;
    width: 54px;
    z-index: 99;
  }

  .burger-icon {
    position: relative;
    float: right;
    width: 30px;
    margin: 10px 0 0 0;
    cursor: pointer;
    display: none;
    z-index: 99;

    div {
      background: $black;
      height: 2px;
      margin: 5px 0;
    }

  }


  @media screen and (max-width: $size-L) {

    .burger-icon {
      display: block;
    }

    .header-title {
      display: none;
    }

    .logo {
      float: left;
      margin-top: 10px;
    }

  }

  @media screen and (max-width: $size-SM) {
    .logo {
      width: 30px;
    }
  }

}