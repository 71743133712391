//
// Main CSS
//

main {

  max-width: $maxContentWidth;
  margin: -60px auto 0 auto;
  padding: 0 0 50px 0;
  @include clearfix;

  @media screen and (max-width: $size-L) {
    margin-top: 0;
    padding: 20px;
  }

}


@media screen and (max-width: $size-XXL) {

  .page-id-8,
  .page-id-1 {
    main {
      margin-top: 0;
    }
  }

}

