//
// Import Base CSS and add custom
//

// Base Settings
@import "base/settings";
@import "base/colors";
@import "base/fonts";

// Mixins
@import "base/mixins";

// Normalize
@import "base/normalize";

// Add global CSS
@import "base/global";

// Add Magnific Popup
@import "lib/magnific_popup/main";


//
// CUSTOM CSS
//

// general

html {
  position: relative;
  min-height: 100%;
}

body {
  margin: 0 0 200px;
  font-family: $baseFont;
  @include font-size(15);
  color: $black;
  background: $white;
}

a {
  color: $black;
}

p {
  margin: 0 0 1em 0;
  line-height: 1.4;
  &:last-child {
  margin-bottom: 0;
}
}

h1 {
  font-family: Montserrat, sans-serif;
  @include font-size(25);
  font-weight: 700;
  text-transform: uppercase;
}
h2 {
  font-family: Montserrat, sans-serif;
  @include font-size(25);
  font-weight: 700;
  text-transform: uppercase;
}
h3 {
  font-family: Montserrat, sans-serif;
  @include font-size(25);
  font-weight: 400;
  text-transform: uppercase;
}


.page {
  position: relative;
  @include clearfix();
}


@import "header";
@import "nav-main";
@import "nav-language";
@import "main";
@import "footer";

@import "lib/slick_slider/base";

@import "ce-text-with-images";
@import "ce-image-grid";
@import "news";
@import "sponsors";