//
// Sponsors CSS
//

.supporter-bar {

  margin: auto;
  padding: 0 0 30px 0;

  max-width: 660px;

  .ce-image-grid {

  }


  h3 {
    @include font-size(14);
    text-transform: none;
  }

  .ce-image-grid-sponsors-wrap {

    overflow: hidden;
    text-align: center;

    figure {
      display: inline-block;
    }

  }

}